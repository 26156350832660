import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import logo from '../../static/icon_eps.png';
import { authenticationService } from '../../Services/authentication.service';
import CircularProgress from '@material-ui/core/CircularProgress';

import './style.css';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div id="login-container">
                <div id="center-logo">
                    <img id="navbar-logo" src={logo} alt="logo" srcSet="" width="128" height="128" />
                    <h2>easyProductSystem</h2>
                </div>
                <h2>Login</h2>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email("E-Mail must be a valid email").required('E-Mail is required'),
                        password: Yup.string().required('Password is required')
                    })}
                    onSubmit={({ email, password }, { setStatus, setSubmitting }) => {
                        setStatus();
                        authenticationService.login(email, password)
                            .then(
                                user => {
                                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                                    this.props.history.push(from);
                                },
                                error => {
                                    setSubmitting(false);
                                }
                            );
                    }}
                    render={({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="email">E-Mail</label>
                                <Field name="email" type="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                <div className="outer">
                                    <div className="wrapper">
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button>
                                        {isSubmitting &&
                                            <CircularProgress size={24} className="circular-progress" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                />
            </div>
        )
    }
}

export { LoginPage };