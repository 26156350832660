import './style.css';
import React from 'react';
import { withRouter } from 'react-router-dom';


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class LicenseInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = { error: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) { this.setState({ value: event.target.value }); }
    handleSubmit(event) {
        event.preventDefault();
        const API_KEY = "1234";
        const headers = { 'x-api-key': API_KEY };
        fetch(`${SERVER_URL}/license/information?licenseKey=${this.state.value}`, { headers })
            .then(response => response.json())
            .then(data => {
                console.debug(data);
                if (data.licenseOwner == null) {
                    this.setState({ error: data.message });
                    return;
                }
                console.debug(data);
                this.props.history.push({ pathname: '/product', state: { data: data } });
            });
    }

    render() {
        var error = this.state.error == '' ? '' : <p id="licenseKeyError" className="form-text text-danger">{this.state.error}</p>;
        return (
            <div id="license-dialog" className="middle-container">
                <h3>LIZENZ EINGABE</h3>
                <form id="license-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="licenseKey">Lizenz-Schlüssel</label>
                        <input type="simple_text" className="form-control" id="licenseKey" aria-describedby="licenseKeyHelp"
                            placeholder="XXXX-XXXX-XXXX-XXXX" name="licenseKey" value={this.state.value} onChange={this.handleChange} />
                        {error}
                        <small id="licenseKeyHelp" className="form-text text-muted">Das ist der Lizenz-Schlüssel den Sie beim Kauf zugesendet bekommen haben.</small>
                    </div>
                    <button type="submit" className="btn btn-primary">Senden</button>
                </form>
            </div>
        );
    }
}

export default withRouter(LicenseInput);
