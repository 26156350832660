import './checklist_properties.css';
import React from 'react';

class ChecklistProperties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.checklist.title,
            author: props.checklist.author,
            description: props.checklist.description,
            version: props.checklist.version,
            allowNotes: props.checklist.allowNotes,
            allowImages: props.checklist.allowImages,
        }
        this.handleChange = this.handleChange.bind(this);
        this.setProperties = this.setProperties.bind(this);
    }

    setProperties(checklist) {
        this.setState({
            title: checklist.title,
            author: checklist.author,
            description: checklist.description,
            version: checklist.version,
            allowNotes: checklist.allowNotes,
            allowImages: checklist.allowImages,
        });
    }

    handleChange(event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
        this.props.checklist[event.target.name] = value;
        this.props.onChange(event.target.name, value);
    }

    render() {
        var rows = [];

        return (<div className="checklist-properties-container component">
            <p className="title">Checklist-Eigenschaften</p>
            <table className="checklist-properties">
                <thead>
                </thead>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>  <input
                            type="simple_text"
                            name="title"
                            placeholder="Name.."
                            value={this.state.title}
                            onChange={this.handleChange}
                        /> </td>
                    </tr>
                    <tr>
                        <td>Beschreibung</td>
                        <td>  <input
                            type="simple_text"
                            name="description"
                            placeholder="Beschreibung.."
                            value={this.state.description}
                            onChange={this.handleChange}
                        /> </td>
                    </tr>
                    <tr>
                        <td>Autor</td>
                        <td>  <input
                            type="simple_text"
                            name="author"
                            placeholder="Autor.."
                            value={this.state.author}
                            onChange={this.handleChange}
                        /> </td>
                    </tr>
                    <tr>
                        <td>Version</td>
                        <td>  <input
                            type="simple_text"
                            name="version"
                            placeholder="1.0"
                            value={this.state.version}
                            onChange={this.handleChange}
                        /> </td>
                    </tr>
                </tbody>
            </table>
        </div>)
    }

}

export default ChecklistProperties;