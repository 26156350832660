import './entry_attributes.css';
import React from 'react';
import uuid from 'react-uuid'
import ReactTooltip from 'react-tooltip';

const labeling = {
    isMandatory: "Pflichtfeld",
    allowImagesAnnotation: "Extra Bilder",
    allowTextAnnotation: "Extra Notizen"
}

const tooltips = {
    isMandatory: "Pflichtfelder müssen ausgefüllt werden",
    allowImagesAnnotation: "Erlaubt das Hinzufügen von extra Bildern zu einem Eintrag",
    allowTextAnnotation: "Erlaubt das Hinzufügen von extra Notizen zu einem Eintrag"
}

class EntryOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: props.attributes,
            currentValue: "",
        }
        this.onAttributeChange = this.onAttributeChange.bind(this);
    }

    onAttributeChange(event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.state.attributes[parseInt(event.target.name)].value = value;
        this.setState({
            ...this.state,
            attributes: this.state.attributes,
        });
        this.props.updateAttributes(this.state.attributes);
    }

    render() {
        var entries = [];
        for (var index in this.state.attributes) {
            var randomId = uuid();
            var attribute = this.state.attributes[index];
            entries.push(<div className="entry-attribute" key={randomId}>
                <label htmlFor={"attribute-" + randomId + "-" + attribute.key} data-tip={tooltips[attribute.key]}>
                    <input type="checkbox" id={"attribute-" + randomId + "-" + attribute.key} name={index}
                        checked onChange={this.onAttributeChange} checked={this.state.attributes[index].value} />{labeling[attribute.key]}</label>
            </div>)
        }
        return (<div className="entry-attributes">
            {entries}
        </div>)
    }
}


export default EntryOptions;