import React from 'react';

import { authenticationService } from 'Services/authentication.service';
import { userService } from 'Services/user.service';


class ForbiddenPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            users: null
        };
    }

    componentDidMount() {
        userService.getAll().then(users => this.setState({ users }));
    }

    render() {
        return (
            <div>
                <h1>You are not allowed to enter this page!</h1>
            </div>
        );
    }
}

export { ForbiddenPage };