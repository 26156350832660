import React from 'react';
import './style.css';
import { licenseService } from 'Services/license.service';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { util } from 'Util';
import { DataGrid, deDE } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

function createData(name, value) {
    return { name, value };
}

class LicensePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            license: null,
            licenseId: props.match.params.id || -1,
            deleteDialogShown: false,
            onDialogAction: null,
        };
    }

    componentDidMount() {
        this.updateLicenses();
    }

    updateLicenses() {
        licenseService.getOwned().then((licenses) => {
            const license = licenses.filter(license => license.id == this.state.licenseId).pop();
            this.setState({ license });
        })
    }

    getValueAsUrl(value) {
        if (value == null)
            return value;
        if (value.toString().startsWith("http")) {
            return <a target="_blank" href={value}>{value}</a>;
        }
        return value;
    }

    handleActivationDelete(activationId) {
        const { license } = this.state;
        var activation = license.activations.filter(a => a.id == activationId).pop();
        this.state.onDialogAction = (key) => {
            if (key == "yes") {
                licenseService.invalidateLicense(license.licenseKey, activation.deviceId)
                    .finally(() => {
                        this.updateLicenses();
                    });
            }
        }
        this.setState({ deleteDialogShown: true });
    }

    handleDialogAction(key) {
        this.setState({ deleteDialogShown: false });
        if (this.state.onDialogAction != null) {
            this.state.onDialogAction(key);
        }
    }

    render() {
        const { license } = this.state;

        var productInformation = {};

        var licenseRows = [];
        var productRows = [];
        var activationColumns = [];
        var activationRows = [];
        var informationRows = [];
        var nameWidget = "";
        if (license) {
            var validFrom = moment(license.licenseValidFrom).format("DD.MM.yyyy");
            var validUntil = license.licenseValidUntil == -1 ? <i>Unbegrenzt</i> : moment(license.licenseValidUntil).format("DD.MM.yyyy");
            licenseRows = [
                createData("Lizenz-Schlüssel", <CopyToClipboard text={license.licenseKey}><span className="copiable" data-tip="Klicken zum Kopieren">{license.licenseKey}<ReactTooltip></ReactTooltip></span></CopyToClipboard>),
                createData("Aktuelle Geräte", <span>{license.activations.length} von {license.maxUsed == -1 ? <i>Unbegrenzt</i> : license.maxUsed} </span>),
                createData("Gültig", <span>{validFrom} bis {validUntil}</span>),
                createData("Plattformen", license.platform == null ? "Keine" : license.platform.split(";").map((s) => util.getPlatformName(s)).join("/")),
            ];
            productRows = [
                createData("Name", license.product.name),
                createData("Beschreibung", license.product.description),
                createData("Aktuelle Version", license.product.version),
            ];

            if (license.product.information)
                for (var info of license.product.information) {
                    productInformation[info.key] = info;
                    if (info.target == "user") {
                        if (info.key.startsWith("release_url")) {
                            info.value += `&licenseKey=${license.licenseKey}`;
                        }
                        if (info.type == "url") {
                            productRows.push(createData(info.name, <a target="_blank" className="text-primary" href={info.value}>{info.description}</a>));
                        } else if (info.type == "email") {
                            productRows.push(createData(info.name, <a href={`mailto:${info.value}`} className="text-primary">{info.description}</a>));
                        } else {
                            productRows.push(createData(info.name, info.value));
                        }
                    }
                }

            activationColumns = [
                // { field: 'id', headerName: 'ID', flex: 1 },
                { field: 'deviceName', headerName: 'Geräte-Name', flex: 1 },
                {
                    field: 'createdAt',
                    headerName: 'Angemeldet am..',
                    flex: 1,
                    sortComparator: (v1, v2, param1, param2) =>
                        moment(v1, "DD.MM.yyyy").utc() - moment(v2, "DD.MM.yyyy").utc(),
                },
                {
                    field: 'lastValidationTime',
                    headerName: 'Zuletzt benutzt am..',
                    flex: 1,
                    sortComparator: (v1, v2, param1, param2) =>
                        moment(v1, "HH:mm:ss DD.MM.yyyy").utc() - moment(v2, "HH:mm:ss DD.MM.yyyy").utc(),
                },
                {
                    field: 'actions', headerName: 'Aktionen', flex: 1,
                    align: "right",
                    sortable: false,
                    disableColumnSelector: true,
                    disableColumnReorder: true,
                    disableColumnMenu: true,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {
                        return <div> <Button variant="outlined" color="secondary" onClick={() => this.handleActivationDelete(params.row.id)} startIcon={<DeleteIcon />}>
                            Löschen
                        </Button></div>
                    }
                },
            ];

            activationRows = [];
            for (var device of license.activations) {
                activationRows.push({
                    id: device.id,
                    deviceName: device.deviceName,
                    createdAt: moment(device.createdAt).format("DD.MM.yyyy"),
                    lastValidationTime: moment(device.lastValidationTime).format("HH:mm:ss DD.MM.yyyy"),
                });
            }
            nameWidget = productInformation["slogan"] == null ? license.product.name : <img height={80} src={productInformation["slogan"].value} style={{ paddingBottom: 16 }}></img>;
        }
        return (
            <div>
                {license &&
                    <div>
                        <h1>Lizenz für {nameWidget}</h1>
                        <p>Hier findest du alle Informationen zu deiner Lizenz.</p>
                        <hr />
                        <br />
                        <Grid container style={{ flexGrow: 1 }} spacing={5}>
                            <Grid item xs={6}>
                                <h2 className="center">Eigenschaften der Lizenz</h2>
                                <br />
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Wert</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {licenseRows.map((row) => (
                                                <TableRow key={row.name}>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {this.getValueAsUrl(row.value)}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={6}>
                                <h2 className="center">Eigenschaften des Produkts</h2>
                                <br />
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Wert</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {productRows.map((row) => (
                                                <TableRow key={row.name}>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">{this.getValueAsUrl(row.value)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <h2 className="center">Aktivierte Geräte</h2>
                                <br />
                                <div style={{ height: 500, width: '100%' }}>
                                    <div style={{ display: 'flex', height: '100%' }}>
                                        <div style={{ flexGrow: 1 }}>
                                            <DataGrid hideFooterSelectedRowCount={true} isRowSelectable={false} loading={license == null} localeText={deDE.props.MuiDataGrid.localeText} columns={activationColumns} rows={activationRows} />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                }
                <Dialog
                    open={this.state.deleteDialogShown}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Aktivierung löschen?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Wenn Sie diese Aktivierung löschen, muss das Gerät neu validiert werden bevor die Anwendung wieder genutzt werden kann.<br />
                            Möchten Sie dieses Gerät entfernen?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.handleDialogAction("no") }} color="primary">
                            Nein
                        </Button>
                        <Button onClick={() => { this.handleDialogAction("yes") }} color="secondary" autoFocus>
                            Ja
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export { LicensePage };