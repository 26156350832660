import './style.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom';
import logoApple from '../../static/icon_apple.svg';
import logoAndroid from '../../static/icon_android.svg';
import Collapsible from 'react-collapsible';

class ProductView extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: '' };
    }


    componentDidMount() {
        const { router, params, location, routes } = this.props
    }

    getOnlyDate(mysqlTimestamp) {
        return String(mysqlTimestamp).split("T")[0].split("-").reverse().join(".");
    }

    render() {
        const { router, params, location, routes } = this.props
        const license = location.state["data"];
        const product = license["product"];
        if (product == null) {
            this.props.history.push({ pathname: '/login', state: { invalid: true } });
            return (<p>Invalid License Key</p>);
        }
        product.releaseURLAndroid += "&licenseKey=" + license.licenseKey;
        product.releaseURLIOS += "&licenseKey=" + license.licenseKey;
        product.lastVersionUpdateAndroid = this.getOnlyDate(product.lastVersionUpdateAndroid);
        product.lastVersionUpdateIOS = this.getOnlyDate(product.lastVersionUpdateIOS);
        var rows = [];
        for (var configEntry of license.configuration) {
            var first = <td className="config-key"> {configEntry.key.replace("_", ' ')} </td>;
            var second;
            if (/#/.test(configEntry.value)) {
                second = <div style={{ 'width': '16px', 'height': '16px', 'backgroundColor': configEntry.value, 'display': 'inline-block' }}></div>;
            } else if (/(http)*(png|jpg|jpeg|svg|gif)/.test(configEntry.value)) {
                console.debug("image " + configEntry.value);
                second = <td> <img src={configEntry.value} alt="" max-height="64px" /></td>;
            } else {
                second = <td> {configEntry.value} </td>;
            }
            rows.push(<tr> {first} {second} </tr>);
        }
        var requestChange = `mailto:product_configuration@pabst-software-design.com?subject=Product%20Change&body=Requesting changes for License-Key: ${license.licenseKey}:`;
        return (
            <div id="product-container" className="middle-container">
                <h3>PRODUCT OVERVIEW</h3>
                <img className="product-image" src={`${product['logoURL']}`} alt="" />
                <h2 className="product-name">{product["name"]}</h2>
                <p className="product-description">{product["description"]}</p>
                <p className="product-version">Version: {product.currentVersionName}</p>
                <div id="platforms-container">
                    <div className="platform-container">
                        <img className="product-image" src={logoAndroid} alt="" />
                        <h4>Android</h4>
                        <p>Last Update: {product.lastVersionUpdateAndroid}</p>
                        <div className="product-actions">
                            <a href={product.supportURLAndroid} className="product-button">Get Support</a>
                            <a href={product.releaseURLAndroid} className="product-button">Get latest
                                version</a>
                        </div>
                    </div>
                    <div className="platform-container">
                        <img className="product-image" src={logoApple} alt="" />
                        <h4>iOS</h4>
                        <p>Last Update: {product.lastVersionUpdateIOS}</p>
                        <div className="product-actions">
                            <a href={product.supportURLIOS} className="product-button">Get Support</a>
                            <a href={product.releaseURLIOS} className="product-button">Get latest
                                version</a>
                        </div>
                    </div>
                </div>
                <Collapsible trigger={<h4> PRODUCT CONFIGURATION</h4>}>
                    <div id="configuration-container">
                        <table className="configuration-table">
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                        <div clas="product-actions">
                            <a href={requestChange} className="product-button">Request changes</a>
                            <hr />
                        </div>
                    </div>
                </Collapsible>

            </div>
        );
    }
}

export default withRouter(ProductView);
