
import './entry_dropdown.css';
import React from 'react';
import uuid from 'react-uuid'

class EntryDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options,
            currentValue: props.value,
        }
        this.onItemSelected = this.onItemSelected.bind(this);
    }

    onItemSelected(event) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            currentValue: value
        });
        this.props.onItemSelected(value);
    }

    render() {
        var entries = [];
        for (var index in this.state.options) {
            var randomId = uuid();
            var attribute = this.state.options[index];
            entries.push(
                <option value={attribute.value}>{attribute.name}</option>
            );
        }
        return (<label>
            {this.props.label}
            <select name="type" value={this.state.currentValue} onChange={this.onItemSelected}>
                {entries}
            </select>
        </label>)
    }
}


export default EntryDropdown;