import { authHeader } from '../Helpers/auth-header';
import { handleResponse } from '../Helpers/handle-response';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const licenseService = {
    getOwned,
    invalidateLicense,
    getAll,
    update,
    create,
    deleteEntity
};

function getOwned() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${SERVER_URL}/license/owned`, requestOptions).then(handleResponse);
}

function invalidateLicense(licenseKey, deviceId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${SERVER_URL}/license/invalidate?licenseKey=${licenseKey}&deviceId=${deviceId}`, requestOptions).then(handleResponse);
}


function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${SERVER_URL}/license/all`, requestOptions).then(handleResponse);
}

function update(license) {
    var headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = { method: 'PUT', headers: headers, body: JSON.stringify(license) };
    return fetch(`${SERVER_URL}/license`, requestOptions).then(handleResponse);
}

function create(license) {
    var headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = { method: 'POST', headers: headers, body: JSON.stringify(license) };
    return fetch(`${SERVER_URL}/license`, requestOptions).then(handleResponse);
}

function deleteEntity(license) {
    var headers = authHeader();
    const requestOptions = { method: 'DELETE', headers: headers };
    return fetch(`${SERVER_URL}/license?id=${license}`, requestOptions).then(handleResponse);
}