import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';

class SimpleDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { title, open, onClose, body, additionalActions, closeLabel } = this.props;
        return <div> <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {additionalActions}
                <Button onClick={onClose} color="primary" autoFocus>
                    {closeLabel ? closeLabel : "Close"}
                </Button>
            </DialogActions>
        </Dialog></div>;
    }
}

export default SimpleDialog;