import "./checklist_entry.css";
import { useDrag } from 'react-dnd'
import React, { useState } from "react";
import OptionAdder from "./option_adder";
import EntryDropdown from "./entry_dropdown";
import EntryAttributes from "./entry_attributes";
import DetailModal from "./detail_modal";
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export default function ChecklistEntry(props) {


    const [title, setTitle] = useState(props.entry.title);
    const [description, setDescription] = useState(props.entry.description);
    const [type, setType] = useState(props.entry.type);
    const [options, setOptions] = useState(props.entry.options);
    const [suffix, setSuffix] = useState(props.entry.suffix);
    const [labelStart, setLabelStart] = useState(props.entry.labelStart);
    const [labelEnd, setLabelEnd] = useState(props.entry.labelEnd);
    const [minValue, setMinValue] = useState(props.entry.minValue);
    const [maxValue, setMaxValue] = useState(props.entry.maxValue);
    const [stepSize, setStepSize] = useState(props.entry.stepSize);
    const [childEntries, setChildEntries] = useState(props.entry.childEntries);
    const [additionalClasses, setAdditionalClasses] = useState(props.additionalClasses ? props.additionalClasses : []);
    const [indentLevel, setIndentLevel] = useState(props.entry.indentLevel);

    const getEntryClasses = (type) => {
        var classes = ["entry"];
        return additionalClasses.concat(classes).join(" ");
    };

    const handleChange = (event, setterMethod) => {
        setterMethod(event.target.value);
        var forceRebuild = false;
        var value;
        if (event.target.type === "checkbox") {
            value = event.target.checked;
        } else if (
            event.target.name == "minValue" ||
            event.target.name == "maxValue" ||
            event.target.name == "stepSize"
        ) {
            value = parseFloat(event.target.value);
        } else {
            value = event.target.value;
        }
        // If changing type -> reset options
        if (event.target.name == "type") {
            if (props.entry.type == "group" ||
                value == "group") {
                forceRebuild = true;
            }
            setOptions([]);
            setSuffix("");
            setLabelStart("");
            setLabelEnd("");
            setMinValue("");
            setMaxValue("");
            setStepSize("");
        }

        props.entry[event.target.name] = value;
        props.onChange(props.entry, forceRebuild);
    }

    const updateOptions = async (options) => {
        setOptions(options);
        props.entry.options = options;
        props.onChange(props.entry);
    }

    const updateAttributes = async (attributes) => {
        for (var attribute of attributes) {
            if (
                [
                    "isMandatory",
                    "allowTextAnnotation",
                    "allowImagesAnnotation",
                ].includes(attribute.key)
            )
                props.entry[attribute.key] = attribute.value;
        }
        props.onChange(props.entry);
    }

    const onDeleteClicked = async () => {
        confirmAlert({
            title: 'Löschen bestätigen',
            message: props.hasChildren ? 'Möchtest du diese Gruppe inklusiver aller Einträge löschen?' : 'Möchtest du diesen Eintrag löschen?',
            buttons: [
                {
                    label: 'Ja, löschen',
                    className: 'bg-danger',
                    onClick: () => {
                        props.entry.deleted = true;
                        props.onChange(props.entry);
                    }
                },
                {
                    label: 'Nein',
                    onClick: () => { }
                }
            ]
        });
    }

    const getEntryAttributes = (
        isMandatory,
        allowTextAnnotation,
        allowImagesAnnotation
    ) => {
        if (type == "group") {
            return <div className="left-auto"></div>;
        } else {
            return (
                <EntryAttributes
                    attributes={[
                        // { key: "isMandatory", value: isMandatory },
                        {
                            key: "allowTextAnnotation",
                            value: allowTextAnnotation,
                        },
                        {
                            key: "allowImagesAnnotation",
                            value: allowImagesAnnotation,
                        },
                    ]}
                    updateAttributes={async () => updateAttributes}
                ></EntryAttributes>
            );
        }
    }

    var extraComponent1 = <div></div>;
    var extraComponent2 = <div></div>;
    if (
        type == "dropdown" ||
        type == "multiple_choice"
    ) {
        extraComponent1 = (<DetailModal
            labelOpen="Optionen bearbeiten"
            labelClose="Optionen speichern"
            setModalBackground={props.setModalBackground}
        >
            <OptionAdder
                options={options}
                updateOptions={async (options) => updateOptions(options)}
            ></OptionAdder>
        </DetailModal>
        );
    } else if (type == "suffix_text") {
        extraComponent1 = (
            <input
                type="simple_text"
                name="suffix"
                placeholder={"Suffix.."}
                value={suffix}
                onChange={async (event) => { handleChange(event, async (value) => { setSuffix(value) }) }}
            />
        );
    } else if (type == "slider") {
        extraComponent2 = (
            <div class="entry-extra-component-2">
                <DetailModal
                    labelOpen="Slider konfigurieren"
                    labelClose="Konfiguration speichern"
                    setModalBackground={props.setModalBackground}
                >
                    <ReactTooltip />
                    <div className="h-stack extra-component-2-content">
                        <div className="v-stack">
                            <input
                                type="simple_text"
                                name="labelStart"
                                placeholder={"Text min. Wert.."}
                                value={labelStart}
                                data-tip={"Der Text für den minimalen Wert (links am Slider) (z.B. 1 oder `Minimal`)"}
                                onChange={async (event) => { handleChange(event, async (value) => { setLabelStart(value) }) }}
                            />
                            <input
                                type="simple_text"
                                name="labelEnd"
                                placeholder={"Text max. Wert.."}
                                value={labelEnd}
                                data-tip={"Der Text für den maximalen Wert (recht am Slider) (z.B. 10 oder `Maximal`)"}
                                onChange={async (event) => { handleChange(event, async (value) => { setLabelEnd(value) }) }}
                            />
                        </div>
                        <div className="v-stack">
                            <input
                                type="number"
                                name="minValue"
                                placeholder={"min. Wert.."}
                                value={minValue}
                                data-tip={"Der minimal einstellbare Wert (z.B. 1)"}
                                onChange={async (event) => { handleChange(event, async (value) => { setMinValue(value) }) }}
                            />
                            <input
                                type="number"
                                name="maxValue"
                                placeholder={"max. Wert.."}
                                value={maxValue}
                                data-tip={"Der maximal einstellbare Wert (z.B. 10)"}
                                onChange={async (event) => { handleChange(event, async (value) => { setMaxValue(value) }) }}
                            />
                        </div>
                        <input
                            type="number"
                            name="stepSize"
                            placeholder={"Schrittweite.."}
                            value={stepSize}
                            data-tip={"Der Schrittweite in welcher der Slider verschoben werden kann"}
                            onChange={async (event) => { handleChange(event, async (value) => { setStepSize(value) }) }}
                        />
                    </div>
                </DetailModal>
            </div>
        );
    }
    return (
        <div
            className="entry-container"
            id={props.entry.id}
        >
            <div className={getEntryClasses(type)}>
                <div className="v-stack">
                    <input
                        key="title"
                        type="simple_text"
                        name="title"
                        placeholder={"Titel.."} //props.entry.id
                        value={title}
                        onChange={(event) => { handleChange(event, (value) => { setTitle(value) }) }}
                    />
                    <input
                        type="simple_text"
                        name="description"
                        placeholder={"Beschreibung.."}
                        value={description}
                        onChange={(event) => { handleChange(event, (value) => { setDescription(value) }) }}
                    />
                </div>
                <div className="v-stack">
                    <label>
                        Typ:
                        <select
                            name="type"
                            value={type}
                            disabled={props.hasChildren}
                            onChange={(event) => { handleChange(event, (value) => { setType(value) }) }}
                        >
                            <option value="group">Gruppe</option>
                            <option value="simple_text">
                                Text/Zahl (einzeilig)
                            </option>
                            <option value="multiline_text">
                                Text/Zahl (mehrzeilig)
                            </option>
                            <option value="checkbox">Checkbox (Ja/Nein)</option>
                            <option value="yes_no_na">
                                Ja/Nein/Keine Angabe
                            </option>
                            <option value="dropdown">Dropdown</option>
                            <option value="multiple_choice">
                                Multiple-Choice
                            </option>
                            <option value="images">Bilder</option>
                            <option value="date">Datum</option>
                            <option value="time">Uhrzeit</option>
                            <option value="date_time">Datum und Uhrzeit</option>
                            <option value="suffix_text">Text mit Suffix</option>
                            <option value="slider">Slider</option>
                        </select>
                    </label>
                    {getEntryAttributes(
                        props.entry.isMandatory,
                        props.entry.allowTextAnnotation,
                        props.entry.allowImagesAnnotation
                    )}
                </div>
                {extraComponent1}
                <div className={getEntryClasses(type)}>
                    {extraComponent2}
                </div>
                <div className="entry-actions">
                    {/* <button onClick={onUpClicked} className="up-button fa-button" />
                <button onClick={onDownClicked} className="down-button fa-button" /> */}
                    <button
                        onClick={() => onDeleteClicked()}
                        className="delete-button fa-button"
                    />
                </div>
            </div>
        </div >
    );
}