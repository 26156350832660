import './checklist_tree.css';
import React, { Component } from 'react';
import SortableTree, { addNodeUnderParent, removeNodeAtPath, getVisibleNodeCount } from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css'; // This only needs to be imported once in your app
import ChecklistNodeRenderer from './checklist_node_renderer';
import ChecklistEntry from './checklist_entry';
import uuid from 'react-uuid';


export default class Tree extends Component {
    constructor(props) {
        super(props);

        var treeData = this.optimizeTreeForInternal(props.initalEntries ? props.initalEntries : []);
        this.state = {
            treeData: treeData,
        };
    }

    importEntries(entries) {
        var optimizedData = this.optimizeTreeForInternal(entries);

        if (entries == null) return;
        this.setState({ treeData: optimizedData });
    }

    createEmptyEntry() {
        return {
            id: uuid(),
            title: "",
            description: "",
            type: "simple_text",
            options: [],
            suffix: "",
            labelStart: "",
            labelEnd: "",
            minValue: 0.0,
            maxValue: 1.0,
            stepSize: 0.2,
            isMandatory: false,
            allowImagesAnnotation: true,
            allowTextAnnotation: true,
            childEntries: [],
        }
    }

    async pushUpdates() {
        this.props.updateEntries(this.optimizeTreeForExport(this.state.treeData));
    }

    optimizeTreeForInternal(tree) {
        if (tree == null) return;
        var newTree = [];
        for (var treeItem of tree) {
            var newItem = {
                "entry": treeItem,
                "children": this.optimizeTreeForInternal(treeItem.childEntries)
            }
            newTree.push(newItem);
        }
        return newTree;
    }

    optimizeTreeForExport(tree) {
        if (tree == null) return;
        var newTree = [];
        for (var treeItem of tree) {
            treeItem.entry.childEntries = this.optimizeTreeForExport(treeItem.children);
            treeItem = treeItem.entry;
            delete treeItem.children;
            newTree.push(treeItem);
        }
        return newTree;
    }

    render() {
        const getNodeKey = ({ node }) => node.entry.id;
        const count = getVisibleNodeCount({ treeData: this.state.treeData });
        return (
            <div className="tree-container">
                <div className="actions">
                    <button className="btn-default"
                        onClick={() =>
                            this.setState(state => ({
                                treeData: addNodeUnderParent({
                                    treeData: state.treeData,
                                    parentKey: null,
                                    expandParent: true,
                                    getNodeKey,
                                    newNode: { entry: this.createEmptyEntry() },
                                    addAsFirstChild: true,
                                }).treeData,
                            }), () => this.pushUpdates())
                        }
                    >
                        Element oben hinzufügen
                    </button>
                </div>
                <div style={{ height: Math.max(300, count * 120 + 20) }}>
                    <SortableTree
                        rowHeight={(treeIndex, node, path) => { return 120; }}
                        treeData={this.state.treeData}
                        onChange={async treeData => {
                            console.log("onChange of treeData called");
                            this.setState({ treeData }, () => this.pushUpdates());
                        }}
                        canNodeHaveChildren={(node) => {
                            return node.entry.type == "group";
                        }}
                        getNodeKey={getNodeKey}
                        nodeContentRenderer={ChecklistNodeRenderer}
                        generateNodeProps={({ node, path }) => {
                            var ret = {
                                updateParent: async (entry, forceRebuild) => {
                                    this.pushUpdates();
                                    if (forceRebuild) {
                                        this.setState(state => ({
                                            treeData: state.treeData
                                        }));
                                    }
                                    if (entry.deleted == true) {
                                        this.setState(state => ({
                                            treeData: removeNodeAtPath({
                                                treeData: state.treeData,
                                                path,
                                                getNodeKey,
                                            }),
                                        }));
                                    }
                                },
                            };
                            if (node.entry.type == "group") {
                                ret["buttons"] = [
                                    <button className="btn-default"
                                        onClick={async () =>
                                            this.setState(state => ({
                                                treeData: addNodeUnderParent({
                                                    treeData: state.treeData,
                                                    parentKey: path[path.length - 1],
                                                    expandParent: true,
                                                    getNodeKey,
                                                    newNode: { entry: this.createEmptyEntry() },
                                                    addAsFirstChild: state.addAsFirstChild,
                                                }).treeData,
                                            }), () => this.pushUpdates())
                                        }
                                    >
                                        Element hinzufügen
                                    </button>,
                                ];
                            }
                            return ret;
                        }}
                    />
                </div>

                <div className="actions">
                    <button className="btn-default"
                        onClick={async () =>
                            this.setState(state => ({
                                treeData: addNodeUnderParent({
                                    treeData: state.treeData,
                                    parentKey: null,
                                    expandParent: true,
                                    getNodeKey,
                                    newNode: { entry: this.createEmptyEntry() },
                                    addAsFirstChild: false,
                                }).treeData,
                            }), () => this.pushUpdates())
                        }
                    >
                        Element unten hinzufügen
                    </button>
                </div>
            </div>
        );
    }
}