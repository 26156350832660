import NavigationBar from './Components/navigation/navigation_bar';
import Footer from './Components/footer/footer';
import LicenseDialog from './Components/license_input/license_input';
import ProductView from './Components/product/product_view';
import ChecklistEditor from './Components/checklist_editor/checklist_editor';
import LandingPage from './Components/landing/landing_page';


import React from 'react';
import { Router, Route, Link } from 'react-router-dom';

import { history } from './Helpers/history';
import { authenticationService } from './Services/authentication.service';
import { AuthorizedRoute } from './Components/AuthorizedRoute';

import './App.css';
import { LoginPage } from './Pages/LoginPage/LoginPage';
import { HomePage } from './Pages/HomePage/HomePage';
import { ForbiddenPage } from './Pages/ForbiddenPage/ForbiddenPage';
import { LicensePage } from './Pages/LicensePage/LicensePage';
import { UsersPage } from 'Pages/UsersPage/UsersPage';
import { ProductsPage } from 'Pages/ProductsPage/ProductsPage';
import { LicenseOverviewPage } from 'Pages/LicenseOverviewPage/LicenseOverviewPage';

if (localStorage.lightmode) {
  document.documentElement.setAttribute('data-theme', 'light')
} else {
  document.documentElement.removeAttribute("data-theme")
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
  }

  logout() {
    authenticationService.logout();
    history.push('/login');
  }

  render() {
    const { currentUser } = this.state;
    var productNavEntries = [];
    var additionalNavEntries = [];
    if (currentUser) {
      var addedProducts = [];
      for (var ownedLicense of currentUser.licenses) {
        var productInformation = ownedLicense.product.information;
        if (addedProducts.includes(ownedLicense.product.id)) {
          continue;
        }
        addedProducts.push(ownedLicense.product.id);
        var entry = { "name": ownedLicense.product.name, links: [] };
        for (var info of productInformation) {
          if (info.type == "url") {
            entry.links.push(info)
          }
        }
        if (entry.links.length != 0)
          productNavEntries.push(entry);
      }
      // Level >= 10 means admin user
      if (currentUser.permissionLevel >= 10) {
        console.log("User has admin privileges!");
        var entry = { name: "Admin", links: [], basePath: "admin" };
        entry.links.push({ name: "Benutzer", value: "/admin/users", remote: false });
        entry.links.push({ name: "Produkte", value: "/admin/products", remote: false });
        entry.links.push({ name: "Lizenzen", value: "/admin/licenses", remote: false });
        additionalNavEntries.push(entry);
      }
    }

    const isActiveClass = (path) => {
      return window.location.pathname.includes(path) ? "active" : "";
    }

    return (
      <Router history={history}>
        <div>
          {currentUser &&
            <nav className="navbar navbar-expand-md navbar-dark bg-dark">
              <div className="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link to="/" onClick={() => this.setState({})} className={window.location.pathname === "/" ? "active nav-item nav-link" : "nav-item nav-link"}>Home</Link>
                  </li>
                  {productNavEntries.map(i => <li className="nav-item dropdown">
                    <a className={"nav-link dropdown-toggle"} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {i.name}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {i.links.map(link => <a target="_blank" className="dropdown-item" href={link.value}>{link.name}</a>)}
                    </div>
                  </li>)}
                  {additionalNavEntries.map(i => <li className="nav-item dropdown">
                    <a className={"nav-link dropdown-toggle " + isActiveClass(i.basePath)} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {i.name}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {i.links.map(link => link.remote ? <a target="_blank" className="dropdown-item" href={link.value}>{link.name}</a>
                        : <Link to={link.value} onClick={() => this.setState({})} className="dropdown-item">{link.name}</Link>)}
                    </div>
                  </li>)}
                </ul>
              </div>
              <div className="mx-auto order-0">
                <a className="navbar-brand mx-auto" href="#">easyProductSystem</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2">
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a onClick={this.logout} className="nav-item nav-link ml-auto pointer">Logout</a>
                  </li>
                </ul>
              </div>
            </nav>
          }
          {
            !currentUser &&
            <nav className="navbar navbar-expand-md navbar-dark bg-dark">
              <div className="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link to="/login" onClick={() => this.setState({})} className={"nav-item nav-link " + isActiveClass("/login")}>Login</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/checklist/editor" onClick={() => this.setState({})} className={"nav-item nav-link " + isActiveClass("/checklist/editor")}>easyCHECK Editor</Link>
                  </li>
                </ul>
              </div>
              <div className="mx-auto order-0">
                <a className="navbar-brand mx-auto" href="#">easyProductSystem</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2">
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
                <ul className="navbar-nav ml-auto">
                </ul>
              </div>
            </nav>
          }
          <div className="jumbotron">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <AuthorizedRoute exact path="/" requiredPermissionLevel="1" component={HomePage} />
                  <AuthorizedRoute exact path="/license/:id" requiredPermissionLevel="1" component={LicensePage} />
                  <AuthorizedRoute exact path="/admin/users" requiredPermissionLevel="10" component={UsersPage} />
                  <AuthorizedRoute exact path="/admin/products" requiredPermissionLevel="10" component={ProductsPage} />
                  <AuthorizedRoute exact path="/admin/licenses" requiredPermissionLevel="10" component={LicenseOverviewPage} />
                  <Route path="/login" component={LoginPage} />
                  <Route path="/forbidden" component={ForbiddenPage}></Route>
                  <Route path="/checklist/editor" component={ChecklistEditor}></Route>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
