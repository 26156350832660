import React from 'react';

import { authenticationService } from 'Services/authentication.service';
import { productService } from 'Services/product.service';
import { DataGrid, deDE } from '@material-ui/data-grid';

import moment from 'moment';
import EditObjectDialog from 'Components/EditObjectDialog/EditObjectDialog';
import EditPropertiesDialog from 'Components/EditPropertiesDialog/EditPropertiesDialog';
import SimpleDialog from 'Components/SimpleDialog/SimpleDialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Tooltip } from '@material-ui/core';

class ProductsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            products: null,
            createDialog: {
                open: false,
                error: ""
            },
            deleteDialog: {
                open: false,
                product: -1
            },
            newProduct: null,
        };
    }

    componentDidMount() {
        this.updateUi();
    }

    updateUi() {
        productService.getAll().then((products) => this.setState({ products }));
    }

    createNewProductDialog() {
        this.state.newProduct = {
            name: "",
            description: "",
            version: "1.0.0"
        }
        const editProperties = {
            buttonLabel: "Neu Erstellen",
            dialogTitle: "Neues Produkt erstellen..",
            dialogDescription: null,
            onSave: (obj) => {
                productService.create(obj).then(resp => {
                    if (resp.error) {
                        this.setState({ createDialog: { open: true, error: "Error: " + resp.error } });
                    }
                    this.updateUi();
                });
            },
            objectToEdit: this.state.newProduct,
            mapping: {
                name: {
                    label: "Name",
                },
                description: {
                    label: "Beschreibung",
                },
                version: {
                    label: "Version"
                }
            }
        };
        return <EditObjectDialog properties={editProperties}></EditObjectDialog>;
    }

    handleProductDelete(id) {
        this.setState({ deleteDialog: { open: true, product: id } });
    }

    deleteProduct(id) {
        this.setState({ deleteDialog: { open: false } });
        productService.deleteEntity(id)
            .catch((error) => {
                console.error(error);
            }).finally(() => {
                this.updateUi();
            });
    }

    renderCellWithTooltip(params) {
        return <Tooltip title={params.value}><span>{params.value}</span></Tooltip>
    }

    render() {
        const { currentUser, products } = this.state;

        const columns = [
            { field: 'product', hide: true },
            { field: 'id', headerName: 'Id', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'description', headerName: 'Beschreibung', flex: 1, renderCell: this.renderCellWithTooltip },
            { field: 'version', headerName: 'Version', flex: 1 },
            { field: 'createdAt', headerName: 'Erstellt am', flex: 1 },
            {
                field: 'actions', headerName: 'Aktionen', flex: 1,
                disableClickEventBubbling: true,
                renderCell: (params) => {
                    const editProperties = {
                        dialogTitle: "Produkt bearbeiten..",
                        dialogDescription: null,
                        onSave: (obj) => {
                            productService.update(obj).then(resp => {
                                if (resp.error) {
                                    this.setState({ createDialog: { open: true, error: "Error: " + resp.error } });
                                }
                                this.updateUi();
                            });
                        },
                        objectToEdit: params.row.product,
                        mapping: {
                            name: {
                                label: "Name",
                            },
                            description: {
                                label: "Beschreibung",
                            },
                            version: {
                                label: "Version",
                            }
                        }
                    };
                    const editPropertiesProperties = {
                        dialogTitle: "Produkt-Eigenschaften",
                        dialogDescription: null,
                        mapping: {
                            type: {
                                type: "select",
                                defaultValue: "text",
                                label: "Typ",
                                values: [
                                    {
                                        value: "text",
                                        label: "Text"
                                    },
                                    {
                                        value: "color",
                                        label: "Color"
                                    },
                                    {
                                        value: "url",
                                        label: "URL"
                                    },
                                    {
                                        value: "number",
                                        label: "Number"
                                    },
                                    {
                                        value: "image",
                                        label: "Image"
                                    },
                                    {
                                        value: "email",
                                        label: "E-Mail"
                                    }
                                ]
                            }
                        },
                        onSave: (properties) => {
                            params.row.product.information = properties;
                            productService.update(params.row.product).then(resp => {
                                if (resp.error) {
                                    this.setState({ createDialog: { open: true, error: "Error: " + resp.error } });
                                }
                                this.updateUi();
                            });
                        },
                    }
                    return <div style={{ display: "contents" }}> <IconButton
                        variant="outlined"
                        color="secondary"
                        onClick={() => this.handleProductDelete(params.row.id)}
                        style={{ padding: 4 }}>
                        <DeleteIcon />
                    </IconButton>
                        <EditObjectDialog
                            properties={editProperties}
                            style={{ padding: 4 }}></EditObjectDialog>
                        <EditPropertiesDialog
                            properties={editPropertiesProperties}
                            editProperties={params.row.product.information}
                            style={{ padding: 4 }}></EditPropertiesDialog>
                    </div>;
                }
            },
        ];

        const rows = [];
        if (products != null)
            for (var product of products) {
                rows.push({
                    product: product,
                    id: product.id,
                    name: product.name,
                    description: product.description,
                    version: product.version,
                    createdAt: moment(product.createdAt).format("DD.MM.yyyy"),
                });
            }

        return (
            <div>
                <h1>Hi Admin!</h1>
                <hr />
                <h2>Alle Produkte:</h2>
                <br />
                <div style={{ height: 400, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid hideFooterSelectedRowCount={true} isRowSelectable={false} loading={products == null} localeText={deDE.props.MuiDataGrid.localeText} columns={columns} rows={rows} />
                        </div>
                    </div>
                </div>
                <SimpleDialog title="Error"
                    open={this.state.createDialog.open}
                    onClose={() => this.setState({ createDialog: { open: false } })}
                    body={this.state.createDialog.error}></SimpleDialog>
                <SimpleDialog title="Produkt löschen?"
                    open={this.state.deleteDialog.open}
                    closeLabel="Abbrechen"
                    onClose={() => this.setState({ deleteDialog: { open: false } })}
                    additionalActions={[<Button onClick={() => this.deleteProduct(this.state.deleteDialog.product)} color="primary" autoFocus>
                        Ja
                    </Button>]}
                    body={"Möchtest du dieses Produkt endgültig löschen? Das kann nicht rückgängig gemacht werden!"}></SimpleDialog>
                <div style={{ padding: 16 }}>
                    {this.createNewProductDialog()}
                </div>
            </div >
        );
    }
}

export { ProductsPage };