import React from 'react';

import { authenticationService } from 'Services/authentication.service';
import { userService } from 'Services/user.service';
import { licenseService } from 'Services/license.service';
import { DataGrid, deDE } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { util } from 'Util';

import { productService } from 'Services/product.service';

import EditObjectDialog from 'Components/EditObjectDialog/EditObjectDialog';
import EditPropertiesDialog from 'Components/EditPropertiesDialog/EditPropertiesDialog';
import SimpleDialog from 'Components/SimpleDialog/SimpleDialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
class LicenseOverviewPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            licenses: null,
            users: null,
            products: null,
            createDialog: {
                open: false,
                error: ""
            },
            deleteDialog: {
                open: false,
                license: -1
            },
            newLicense: null,
        };
        this.renderActionsCell.bind(this);
    }

    componentDidMount() {
        this.updateUi();
    }

    updateUi() {
        licenseService.getAll().then(licenses => this.setState({ licenses }));
        userService.getAll().then(users => this.setState({ users }));
        productService.getAll().then(products => this.setState({ products }));
    }

    createNewLicenseDialog() {
        this.state.newLicense = {
            licenseKey: "",
            licenseValidFrom: Date.now(),
            licenseValidUntil: Date.now(),
            licenseInfinity: false,
            maxUsed: -1,
            productId: -1,
            userId: -1,
            platform: "",
            licenseEnabled: true,
        }
        const editProperties = {
            buttonLabel: "Neu Erstellen",
            dialogTitle: "Neue Lizenz erstellen..",
            dialogDescription: null,
            onSave: (obj) => {
                licenseService.create(obj).then(resp => {
                    if (resp.error) {
                        this.setState({ createDialog: { open: true, error: "Error: " + resp.error } });
                    }
                    this.updateUi();
                });
            },
            objectToEdit: this.state.newLicense,
            mapping: {
                order: ["productId",
                    "userId",
                    "licenseKey",
                    "maxUsed",
                    "platform",
                    "licenseValidFrom",
                    "licenseInfinity",
                    "licenseValidUntil",
                    "licenseEnabled"
                ],
                licenseKey: {
                    label: "Lizenz-Schlüssel",
                },
                licenseValidFrom: {
                    label: "Gültig von..",
                    type: "date",
                },
                licenseValidUntil: {
                    label: "Gültig bis..",
                    type: "date",
                    onChange: (value, obj) => {
                        obj.licenseInfinity = isNaN(value).toString();
                        return obj;
                    }
                },
                licenseInfinity: {
                    label: "Unendlich gültig",
                    type: "select",
                    value: 'false',
                    values: [
                        {
                            value: "false",
                            label: "Nein"
                        },
                        {
                            value: "true",
                            label: "Ja"
                        },
                    ],
                    onChange: (value, obj) => {
                        obj.licenseValidUntil = value == "true" ? -1 : Date.now();
                        return obj;
                    },
                },
                maxUsed: {
                    label: "Maximale Geräte"
                },
                productId: {
                    label: "Produkt",
                    type: "select",
                    values: this.state.products == null ? [] : this.state.products.map(p => { return { "value": p.id, "label": p.name } }),
                },
                userId: {
                    label: "Benutzer",
                    type: "select",
                    values: this.state.users == null ? [] : this.state.users
                        .map(p => { return { "value": p.id, "label": p.lastname + " " + p.firstname } })
                        .sort((a, b) => a.label.localeCompare(b.label)),
                },
                platform: {
                    label: "Platform"
                },
                licenseEnabled: {
                    label: "Lizenz aktiviert",
                    type: "select",
                    value: 'false',
                    values: [
                        {
                            value: "false",
                            label: "Nein"
                        },
                        {
                            value: "true",
                            label: "Ja"
                        },
                    ]
                }
            }
        };
        return <EditObjectDialog properties={editProperties}></EditObjectDialog>;
    }

    handleLicenseDelete(id) {
        this.setState({ deleteDialog: { open: true, license: id } });
    }

    deleteLicense(id) {
        this.setState({ deleteDialog: { open: false } });
        licenseService.deleteEntity(id)
            .catch((error) => {
                console.error(error);
            }).finally(() => {
                this.updateUi();
            });
    }

    renderCellWithTooltip(params) {
        return <Tooltip title={params.value}><span>{params.value}</span></Tooltip>
    }

    renderActionsCell(params) {
        if (params.row.license.licenseInfinity == null) {
            params.row.license.licenseInfinity = params.row.license.licenseValidUntil == -1;
        }
        if (this.state.products == null) {
            return <div>Could not load products..</div>;
        }
        if (this.state.users == null) {
            return <div>Could not load users..</div>;
        }
        const editProperties = {
            dialogTitle: "Lizenz bearbeiten..",
            dialogDescription: null,
            onSave: (obj) => {
                licenseService.update(obj).then(resp => {
                    if (resp.error) {
                        this.setState({ createDialog: { open: true, error: "Error: " + resp.error } });
                    }
                    this.updateUi();
                });
            },
            objectToEdit: params.row.license,
            mapping: {
                order: ["productId",
                    "userId",
                    "licenseKey",
                    "maxUsed",
                    "platform",
                    "licenseValidFrom",
                    "licenseInfinity",
                    "licenseValidUntil",
                    "licenseEnabled"
                ],
                licenseKey: {
                    label: "Lizenz-Schlüssel",
                },
                licenseValidFrom: {
                    label: "Gültig von..",
                    type: "date",
                },
                licenseValidUntil: {
                    label: "Gültig bis..",
                    type: "date",
                    onChange: (value, obj) => {
                        obj.licenseInfinity = isNaN(value).toString();
                        return obj;
                    }
                },
                licenseInfinity: {
                    label: "Unendlich gültig",
                    type: "select",
                    value: 'false',
                    values: [
                        {
                            value: "false",
                            label: "Nein"
                        },
                        {
                            value: "true",
                            label: "Ja"
                        },
                    ],
                    onChange: (value, obj) => {
                        obj.licenseValidUntil = value == "true" ? -1 : Date.now();
                        return obj;
                    },
                },
                maxUsed: {
                    label: "Maximale Geräte"
                },
                productId: {
                    label: "Produkt",
                    type: "select",
                    values: this.state.products.map(p => { return { "value": p.id, "label": p.name } }),
                },
                userId: {
                    label: "Benutzer",
                    type: "select",
                    values: this.state.users
                        .map(p => { return { "value": p.id, "label": p.lastname + " " + p.firstname } })
                        .sort((a, b) => a.label.localeCompare(b.label)),
                },
                platform: {
                    label: "Platform"
                },
                licenseEnabled: {
                    label: "Lizenz aktiviert",
                    type: "select",
                    value: 'false',
                    values: [
                        {
                            value: "false",
                            label: "Nein"
                        },
                        {
                            value: "true",
                            label: "Ja"
                        },
                    ]
                }
            }
        };
        const editPropertiesProperties = {
            dialogTitle: "Lizenz-Eigenschaften",
            dialogDescription: null,
            mapping: {
                type: {
                    type: "select",
                    defaultValue: "text",
                    label: "Typ",
                    values: [
                        {
                            value: "text",
                            label: "Text"
                        },
                        {
                            value: "color",
                            label: "Color"
                        },
                        {
                            value: "url",
                            label: "URL"
                        },
                        {
                            value: "number",
                            label: "Number"
                        },
                        {
                            value: "image",
                            label: "Image"
                        },
                        {
                            value: "email",
                            label: "E-Mail"
                        }
                    ]
                }
            },
            onSave: (properties) => {
                params.row.license.configuration = properties;
                params.row.license.configuration.forEach(item => delete item.id);
                licenseService.update(params.row.license).then(resp => {
                    if (resp.error) {
                        this.setState({ createDialog: { open: true, error: "Error: " + resp.error } });
                    }
                    this.updateUi();
                });
            },
        }
        return <div style={{ display: "contents" }}> <IconButton
            variant="outlined"
            color="secondary"
            onClick={() => this.handleLicenseDelete(params.row.id)}
            style={{ padding: 4 }}>
            <DeleteIcon />
        </IconButton>
            <EditObjectDialog
                properties={editProperties}
                style={{ padding: 4 }}></EditObjectDialog>
            <EditPropertiesDialog
                properties={editPropertiesProperties}
                editProperties={params.row.license.configuration}
                style={{ padding: 4 }}></EditPropertiesDialog>
        </div>;
    }

    render() {
        const { currentUser, users, licenses } = this.state;

        const columns = [
            { field: 'productId', hide: true },
            { field: 'productName', headerName: 'Produkt', flex: 1 },
            { field: 'user', headerName: 'Besitzer', flex: 1, renderCell: this.renderCellWithTooltip },
            { field: 'licenseKey', headerName: 'Lizenz-Schlüssel', flex: 1 },
            { field: 'currentUsed', headerName: 'Aktuelle Geräte', flex: 1 },
            { field: 'maxUsed', headerName: 'Maximale Geräte', flex: 1 },
            { field: 'validFrom', headerName: 'Gültig von', flex: 1 },
            { field: 'validTo', headerName: 'Gültig bis', flex: 1 },
            { field: 'licenseEnabled', headerName: 'Lizenz aktiviert', flex: 1 },
            { field: 'platforms', headerName: 'Plattformen', flex: 1 },
            {
                field: 'actions', headerName: 'Aktionen', flex: 1,
                disableClickEventBubbling: true,
                renderCell: this.renderActionsCell.bind(this)
            },
        ];

        const rows = [];
        if (licenses != null)
            for (var license of licenses) {
                rows.push({
                    license: license,
                    id: license.id,
                    productId: license.product.id,
                    user: license.user == null ? "Keiner" : license.user.firstname + " " + license.user.lastname + " (" + license.user.company + ")",
                    productName: license.product.name,
                    licenseKey: license.licenseKey,
                    licenseEnabled: license.licenseEnabled,
                    currentUsed: license.activations.length,
                    maxUsed: license.maxUsed == -1 ? 'Unbegrenzt' : license.maxUsed,
                    validFrom: moment(license.licenseValidFrom).format("DD.MM.yyyy"),
                    validTo: license.licenseValidUntil == -1 ? 'Unbegrenzt' : moment(license.licenseValidUntil).format("DD.MM.yyyy"),
                    platforms: license.platform == null ? "Keine" : license.platform.split(";").map((s) => util.getPlatformName(s)).join("/")
                });
            }

        return (
            <div>
                <h1>Hi Admin!</h1>
                <hr />
                <h2>Alle Lizenzen:</h2>
                <br />
                <div style={{ height: 600, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid hideFooterSelectedRowCount={true} isRowSelectable={false} loading={licenses == null} localeText={deDE.props.MuiDataGrid.localeText} columns={columns} rows={rows} />
                        </div>
                    </div>
                </div>
                <SimpleDialog title="Error"
                    open={this.state.createDialog.open}
                    onClose={() => this.setState({ createDialog: { open: false } })}
                    body={this.state.createDialog.error}></SimpleDialog>
                <SimpleDialog title="Lizenz löschen?"
                    open={this.state.deleteDialog.open}
                    closeLabel="Abbrechen"
                    onClose={() => this.setState({ deleteDialog: { open: false } })}
                    additionalActions={[<Button onClick={() => this.deleteLicense(this.state.deleteDialog.license)} color="primary" autoFocus>
                        Ja
                    </Button>]}
                    body={"Möchtest du diese Lizenz endgültig löschen? Das kann nicht rückgängig gemacht werden!"}></SimpleDialog>
                <div style={{ padding: 16 }}>
                    {this.createNewLicenseDialog()}
                </div>
            </div >
        );
    }
}

export { LicenseOverviewPage };