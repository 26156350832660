import { authHeader } from '../Helpers/auth-header';
import { handleResponse } from '../Helpers/handle-response';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const productService = {
    getAll,
    create,
    deleteEntity,
    update,
};

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${SERVER_URL}/product/all`, requestOptions).then(handleResponse);
}

function update(product) {
    var headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = { method: 'PUT', headers: headers, body: JSON.stringify(product) };
    return fetch(`${SERVER_URL}/product`, requestOptions).then(handleResponse);
}

function create(product) {
    var headers = authHeader();
    headers['Content-Type'] = 'application/json';
    const requestOptions = { method: 'POST', headers: headers, body: JSON.stringify(product) };
    return fetch(`${SERVER_URL}/product`, requestOptions).then(handleResponse);
}

function deleteEntity(product) {
    var headers = authHeader();
    const requestOptions = { method: 'DELETE', headers: headers };
    return fetch(`${SERVER_URL}/product?id=${product}`, requestOptions).then(handleResponse);
}