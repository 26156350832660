import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uuid from 'react-uuid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
class EditObjectDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            objectToEdit: this.props.properties.objectToEdit,
            open: false,
        }
    }

    render() {
        const { buttonLabel, dialogTitle, dialogDescription, mapping, onSave } = this.props.properties;
        var object = this.state.objectToEdit;

        const applyChange = (field, newValue) => {
            object[field] = newValue;
            if (mapping[field] != null && mapping[field].onChange != null) {
                object = mapping[field].onChange(newValue, object);
            }
            this.setState({ object });
        }

        const handleClickOpen = () => {
            this.setState({ open: true });
        };

        const handleClose = () => {
            this.setState({ open: false });
        };

        const handleSave = () => {
            this.setState({ open: false });
            onSave(object);
        };

        var formFields = [];
        const order = mapping.order == null ? Object.keys(object) : mapping.order;
        for (const key of order) {
            var variableType = typeof object[key];
            if (mapping[key] == null) {
                // console.warn("[EditObjectDialog] No configuration found for " + key + ", skipping!");
                continue;
            }
            if (mapping[key] != null && mapping[key].type == "select" && mapping[key].values != null) {
                formFields.push(<FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="demo-simple-select-outlined-label">{mapping[key].label}</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={object[key]}
                        onChange={(event) => applyChange(key, event.target.value)}
                        label={mapping[key].label}
                    >
                        {
                            mapping[key].values.map(value => <MenuItem value={value.value} >{value.label}</MenuItem>)
                        }
                    </Select>
                </FormControl>)
            } else if (mapping[key] != null && mapping[key].type == "date") {
                formFields.push(<TextField
                    id={key}
                    label={mapping[key] != null ? mapping[key].label : key}
                    type={object[key] == -1 ? "text" : "date"}
                    value={object[key] == -1 ? "Unbegrenzt" : moment(object[key]).format("yyyy-MM-DD")}
                    onChange={(event) => {
                        var value = moment(event.target.value).valueOf();
                        applyChange(key, isNaN(value) ? moment().valueOf() : value)
                    }}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />)
            } else if (variableType == "number") {
                formFields.push(<TextField
                    id={key}
                    label={mapping[key] != null ? mapping[key].label : key}
                    type="number"
                    value={object[key]}
                    onChange={(event) => applyChange(key, parseInt(event.target.value))}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />)
            } else {
                formFields.push(<TextField
                    id={key}
                    label={mapping[key] != null ? mapping[key].label : key}
                    type="text"
                    value={object[key]}
                    onChange={(event) => applyChange(key, event.target.value)}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />)
            }
        }

        return (
            <div>
                {buttonLabel ? <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    {buttonLabel}
                </Button> :
                    <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                }
                <Dialog fullWidth={"sm"}
                    maxWidth={"sm"} open={this.state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {dialogDescription}
                        </DialogContentText>
                        {formFields.map(item => <div>{item}</div>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={handleSave} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default EditObjectDialog;