import "./checklist_editor.css";
import React from "react";
import ChecklistProperties from "./checklist_properties";
import {
    checklistToJSON,
    checklistFromJSON,
    checklistFromCSV,
} from "./checklist_io";
import uuid from "react-uuid";
import ChecklistTree from "./checklist_tree";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

var checklist_empty = {
    jsonVersion: 1,
    templateLastSaved: 0,
    type: "checklist",
    title: "",
    description: "",
    author: "",
    version: "",
    childEntries: [],
};

var checklist = Object.assign({}, checklist_empty);

var timeout = 200;
var lastDragnDrop = -1;

class ChecklistEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checklist: checklist,
            error: "",
        };
        this.offerDownload = this.offerDownload.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.properties = React.createRef();
        this.checklistTree = React.createRef();
        this.updateEntries = this.updateEntries.bind(this);
        this.findEntryById = this.findEntryById.bind(this);
    }

    componentDidMount() {
        var checklistCache = localStorage.getItem("checklist_in_edit");
        if (checklistCache) {
            console.log("==== Checklist found in cache ====");
            console.log(checklistCache);
            console.log("==== Importing...             ====");
            checklist = checklistFromJSON(checklistCache);
            this.setState({ checklist: checklist });
            this.properties.current.setProperties(checklist);
            this.checklistTree.current.importEntries(checklist.childEntries);
        }
    }

    isParent(parent, id) {
        return this.findEntryById(id, parent.childEntries) != null;
    }

    findEntryById(id, childEntries) {
        if (childEntries == null) return null;
        for (var entry of childEntries) {
            if (entry.id === id) return entry;
            var nextResult = this.findEntryById(id, entry.childEntries);
            if (nextResult != null) return nextResult;
        }
        return null;
    }

    offerDownload() {
        var checklistInJSON;
        try {
            checklistInJSON = checklistToJSON(checklist);
        } catch (e) {
            this.setState({ error: "Fehler: " + e.message });
            return;
        }
        this.setState({ error: "" });

        console.debug(checklistInJSON);
        var FileSaver = require("file-saver");
        var blob = new Blob([checklistInJSON], {
            type: "application/json;charset=utf-8",
        });
        FileSaver.saveAs(
            blob,
            "Checklist_" + checklist.title.replaceAll(" ", "_") + ".json"
        );
    }

    onLoad(event) {
        var file = event.target.files[0];
        //var csvType = /csv.*/;
        var csvType = "application/vnd.ms-excel";
        var jsonType = "application/json";

        if (file.type.match(jsonType) || file.type.match(csvType)) {
            var reader = new FileReader();

            reader.onload = (e) => {
                var content = reader.result;
                //Here the content has been read successfuly
                if (file.type.match(csvType)) {
                    checklist = checklistFromCSV(content);
                } else {
                    checklist = checklistFromJSON(content);
                }
                this.setState({ checklist: checklist });
                this.properties.current.setProperties(checklist);
                this.checklistTree.current.importEntries(checklist.childEntries);
            };

            reader.readAsText(file);
        }
    }

    async updateEntries(childEntries) {
        checklist.childEntries = childEntries;
        this.cacheCurrentChecklist();
        console.debug("#### ChecklistEditor:updateEntries Called");
        this.printEntriesAsTree("ChecklistEditor", 0, childEntries);
        console.debug("#### // ChecklistEditor:updateEntries Called");
    }

    async cacheCurrentChecklist() {
        localStorage.setItem("checklist_in_edit", checklistToJSON(checklist, true));
    }

    printEntriesAsTree(prefix, depth, childEntries) {
        for (var entry of childEntries) {
            console.debug(
                "[" +
                prefix +
                "] " +
                "---".repeat(depth) +
                entry.title +
                " " +
                entry.type +
                " " +
                entry.id
            );
            this.printEntriesAsTree(
                prefix,
                depth + 1,
                entry["childEntries"] != null ? entry.childEntries : []
            );
        }
    }

    render() {
        var error =
            this.state.error == "" ? (
                ""
            ) : (
                <p className="error">{this.state.error}</p>
            );
        return (
            <div id="main" className="container">
                <div className="introduction">
                    <h1>Checklist-Editor</h1>
                    <p>
                        Mit dem Checklist-Editor ist es einfach neue Checklists
                        zu erstellen oder bestehende zu bearbeiten.
                    </p>
                </div>
                <div id="import-container" className="component">
                    <h1 className="title">Importieren</h1>
                    <p>
                        {" "}
                        Um eine Checklist zu importieren, einfach hier die Datei
                        auswählen. Achtung: nur Checklists die mit diesem Editor
                        erstellt wurden können zuverlässig importiert werden.
                    </p>
                    <input type="file" id="fileInput" onChange={this.onLoad} />
                </div>
                <div id="export-container" className="component">
                    <h1 className="title">Exportieren</h1>
                    <p>
                        {" "}
                        Um eine Checklist zu exportieren, einfach auf `Checklist herunterladen` klicken.
                        Die heruntergeladene Checklist kann direkt in easyCheck importiert werden.
                    </p>
                    <div className="checklist-output">
                        <div id="output-actions">
                            <button
                                id="download-button"
                                className="btn-default"
                                onClick={this.offerDownload}
                            >
                                Checklist herunterladen
                            </button>
                        </div>
                        <div className="error-infos">{error}</div>
                    </div>
                </div>
                <div className="checklist-container">
                    <ChecklistProperties
                        ref={this.properties}
                        checklist={this.state.checklist}
                        onChange={(key, value) => {
                            this.setState({ checklist: checklist }, () => this.cacheCurrentChecklist());
                        }
                        }
                    />
                    <div
                        className="checklist-properties-container component"
                        onDragOver={(ev) => ev.preventDefault()}
                    >
                        <p className="title">Checklist-Einträge</p>
                        <ChecklistTree
                            ref={this.checklistTree}
                            initialEntries={checklist.childEntries}
                            updateEntries={this.updateEntries}
                        >

                        </ChecklistTree>
                        <p className="center text-secondary">
                            <i>Beim Auftreten von graphischen Fehlern, einfach Seite neuladen (Taste F5)</i>
                        </p>
                    </div>
                </div>
                <div className="component">
                    <h1 className="title">Aktionen</h1>
                    <div>
                        <div>
                            <button
                                className="btn-default btn-danger"
                                onClick={() => {
                                    confirmAlert({
                                        title: 'Zurücksetzen bestätigen',
                                        message: 'Möchtest du alle Eigenschaften und Einträge löschen?',
                                        buttons: [
                                            {
                                                label: 'Ja, alles löschen',
                                                className: 'bg-danger',
                                                onClick: () => {
                                                    checklist = Object.assign({}, checklist_empty);
                                                    this.setState({ checklist: checklist }, () => {
                                                        this.cacheCurrentChecklist();
                                                        this.properties.current.setProperties(checklist);
                                                        this.checklistTree.current.importEntries(checklist.childEntries);
                                                    });
                                                }
                                            },
                                            {
                                                label: 'Nein',
                                                onClick: () => { }
                                            }
                                        ]
                                    });
                                }}
                            >
                                Checklist zurücksetzen
                            </button>
                        </div>
                    </div>
                </div>
                <div className="component">
                    <h1 className="title">Informationen</h1>
                    <div>
                        <table className="table">
                            <tr>
                                <th>Bezeichnung</th>
                                <th>Erklärung</th>
                            </tr>
                            <tr>
                                <td>Extra Notizen</td>
                                <td>Erlaubt das Hinzufügen von extra Notizen zu einem Eintrag</td>
                            </tr>
                            <tr>
                                <td>Extra Bilder</td>
                                <td>Erlaubt das Hinzufügen von extra Bildern zu einem Eintrag</td>
                            </tr>
                        </table>
                        <p className="center">
                            <a target="_blank" href="https://pabst-software-design.com/index.php/dokumentation-easycheck/">Zur Dokumentation der App: Hier klicken</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChecklistEditor;
