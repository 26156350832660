import React from 'react';

import { authenticationService } from 'Services/authentication.service';
import { userService } from 'Services/user.service';
import { licenseService } from 'Services/license.service';
import { DataGrid, deDE, GridApi, Record, GridCellValue } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { AuthorizedRoute } from 'Components/AuthorizedRoute';
import { Link } from 'react-router-dom';
import { util } from 'Util';
class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            licenses: null,
        };
    }

    componentDidMount() {
        licenseService.getOwned().then(licenses => this.setState({ licenses }));
    }

    render() {
        const { currentUser, users, licenses } = this.state;

        const columns = [
            // { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'product', headerName: 'Produkt', flex: 1 },
            { field: 'licenseKey', headerName: 'Lizenz-Schlüssel', flex: 1 },
            { field: 'currentUsed', headerName: 'Aktuelle Geräte', flex: 1 },
            { field: 'maxUsed', headerName: 'Maximale Geräte', flex: 1 },
            { field: 'validFrom', headerName: 'Gültig von', flex: 1 },
            { field: 'validTo', headerName: 'Gültig bis', flex: 1 },
            { field: 'platforms', headerName: 'Plattformen', flex: 1 },
            {
                field: 'actions', headerName: 'Aktionen', flex: 1,
                disableClickEventBubbling: true,
                renderCell: (params) => {
                    return <Link to={"/license/" + params.row.id} className="nav-item nav-link">Mehr</Link>
                }
            },
        ];

        const rows = [];
        if (licenses != null)
            for (var license of licenses) {
                rows.push({
                    id: license.id,
                    product: license.product.name,
                    licenseKey: license.licenseKey,
                    currentUsed: license.activations.length,
                    maxUsed: license.maxUsed == -1 ? 'Unbegrenzt' : license.maxUsed,
                    validFrom: moment(license.licenseValidFrom).format("DD.MM.yyyy"),
                    validTo: license.licenseValidUntil == -1 ? 'Unbegrenzt' : moment(license.licenseValidUntil).format("DD.MM.yyyy"),
                    platforms: license.platform == null ? "Keine" : license.platform.split(";").map((s) => util.getPlatformName(s)).join("/")
                });
            }

        return (
            <div>
                <h1>Hi {currentUser.firstname}!</h1>
                <p>Willkommen auf der Startseite des easyProductSystem!</p>
                <hr />
                <h2>Deine Lizenzen:</h2>
                <br />
                <div style={{ height: 400, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid hideFooterSelectedRowCount={true} isRowSelectable={false} loading={licenses == null} localeText={deDE.props.MuiDataGrid.localeText} columns={columns} rows={rows} />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export { HomePage };