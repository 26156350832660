import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import uuid from 'react-uuid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import RestoreIcon from '@material-ui/icons/Restore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';

class EditPropertiesDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editProperties: this.props.editProperties,
            open: false,
            currentBackups: {},
        }
    }

    componentDidUpdate() {
        this.state.editProperties = this.props.editProperties;
    }


    render() {
        const { buttonLabel, dialogTitle, dialogDescription, mapping, onSave } = this.props.properties;
        var properties = this.state.editProperties;


        const applyChange = (id, field, value) => {
            properties.find(item => item.id == id)[field] = value;
            this.setState({ editProperties: properties });
        }

        const deleteProperty = (id) => {
            properties = properties.filter((i) => i.id != id);
            this.setState({ editProperties: properties });
        }

        const addNewProperty = () => {
            properties.push({ id: uuid(), createdAt: Date.now(), editMode: true });
            this.setState({ editProperties: properties });
        }


        const performRollback = (row) => {
            if (this.state.currentBackups[row.id] == null)
                return;
            const index = properties.findIndex((el) => el.id == row.id);
            properties[index] = Object.assign({}, this.state.currentBackups[row.id]);
            this.setState({ properties });
        }

        const handleClickOpen = () => {
            this.setState({ open: true });
        };

        const handleClose = () => {
            properties.filter(i => i.editMode).forEach(i => performRollback(i));
            properties.filter(i => i.editMode).forEach(i => i.editMode = false);
            this.setState({ properties });
            this.state.currentBackups = {};
            this.setState({ open: false });
        };

        const handleSave = () => {
            properties.forEach(i => i.editMode = false);
            this.state.currentBackups = {};
            this.setState({ open: false });
            onSave(properties);
        };

        const getField = (id, field, label, value) => {
            if (mapping[field] == null)
                return <TextField
                    id={id}
                    label={label}
                    type="text"
                    value={value == null ? "" : value}
                    onChange={(event) => applyChange(id, field, event.target.value)}
                    // variant="outlined"
                    fullWidth
                />
            var conf = mapping[field];
            if (conf.type == "select") {
                return <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="demo-simple-select-outlined-label">{conf.label}</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={value == null ? conf.defaultValue : value}
                        onChange={(event) => applyChange(id, field, event.target.value)}
                        label={conf.label}
                    >
                        {
                            conf.values.map(value => <MenuItem value={value.value} >{value.label}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            }
        }

        var entryRows = [];
        if (properties != null)
            for (const property of properties) {
                entryRows.push(property)
            }


        console.log(entryRows);

        return (
            <div>
                {buttonLabel ? <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    {buttonLabel}
                </Button> :
                    <Tooltip title="Konfiguration">
                        <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                }
                <Dialog fullWidth={"xl"}
                    maxWidth={"xl"} open={this.state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {dialogDescription}
                        </DialogContentText>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Erstellt am</TableCell>
                                        <TableCell>Ziel</TableCell>
                                        <TableCell>Typ</TableCell>
                                        <TableCell>Key</TableCell>
                                        <TableCell align="right">Wert</TableCell>
                                        <TableCell align="right">Aktion</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entryRows.map((row) => (row.editMode ?
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                {getField(row.id, "name", "Name", row.name)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {getField(row.id, "description", "Description", row.description)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {moment(row.createdAt).format("HH:mm:ss DD.MM.yyyy")}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {getField(row.id, "target", "Target", row.target)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {getField(row.id, "type", "Type", row.type)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {getField(row.id, "key", "Key", row.key)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {getField(row.id, "value", "Wert", row.value)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="Speichern">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => { row.editMode = false; this.setState({}); }}
                                                        style={{ padding: 4 }}>
                                                        <SaveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Zurücksetzen">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => { performRollback(row); this.setState({}); }}
                                                        style={{ padding: 4 }}>
                                                        <RestoreIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <TableRow key={row.key}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.description}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {moment(row.createdAt).format("HH:mm:ss DD.MM.yyyy")}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.target}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.type}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.key}
                                            </TableCell>
                                            <TableCell align="right">{row.value}</TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="Bearbeiten">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => { row.editMode = true; this.state.currentBackups[row.id] = Object.assign({}, row); this.setState({}); }}
                                                        disabled={properties != null && properties.filter(row => row.editMode).length != 0}
                                                        style={{ padding: 4 }}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Löschen">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => deleteProperty(row.id)}
                                                        style={{ padding: 4 }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <div style={{ padding: 16 }}>
                                <Button variant="outlined" color="primary" disabled={properties != null && properties.filter(row => row.editMode).length != 0} onClick={addNewProperty}>
                                    Hinzufügen
                                </Button>
                            </div>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={handleSave} disabled={properties != null && properties.filter(row => row.editMode).length != 0} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default EditPropertiesDialog;