import './style.css';
import React from 'react';
import { withRouter } from 'react-router-dom';

class LandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: '' };
    }

    render() {
        return (
            <div id="main" className="container">
                <h1 className="title">Willkommen beim easyProductSystem</h1>
                <p>Das easyProductSystem bietet verschiedene Dienste für unsere Produkte an.</p>
                <p>Über den Login kommen Sie zu Ihrem Profil und finden Ihr damit verknüpftes Produkt.</p>
                <p>Nach dem Login finden Sie dann weitere Funktionen zu Ihrem Produkt.</p>
            </div>
        );
    }
}

export default withRouter(LandingPage);
