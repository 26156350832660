import './option_adder.css';
import React from 'react';

class OptionAdder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options ? props.options : [],
            currentValue: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.onAddClicked = this.onAddClicked.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    handleChange(event) {
        this.setState({ currentValue: event.target.value });
    }

    onAddClicked() {
        if (this.state.options.includes(this.state.currentValue) || this.state.currentValue == "") {
            return false;
        }
        this.state.options.push(this.state.currentValue);
        this.setState({ options: this.state.options, currentValue: "" });
        this.props.updateOptions(this.state.options);
    }

    onDeleteClicked(item) {
        this.state.options = this.state.options.filter((e) => e != item);
        this.setState({ options: this.state.options });
        this.props.updateOptions(this.state.options);
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.onAddClicked();
        }
    }

    render() {
        return (<div className="option-adder">
            <div className={"input " + (this.state.options.length > 0 ? "b-bottom" : "")}>
                <input placeholder="Neue Option.." type="simple_text" onKeyDown={this._handleKeyDown} value={this.state.currentValue} onChange={this.handleChange} name="" id="" />
                <button className="optionAddButton" onClick={this.onAddClicked}></button>
            </div>
            <ul className="options">
                {[...this.state.options].reverse().map((e) => <li key={Math.random()}><p>{e}</p> <button className="delete-button fa-button" onClick={() => this.onDeleteClicked(e)}></button></li>)}
            </ul>
        </div>)
    }
}


export default OptionAdder;