import './detail_modal.css';
import React, { useState } from "react";


export default function DetailModal(props) {

    const [showContent, setShowContent] = useState(false);

    const labelOpen = props.labelOpen || "Open";
    const labelClose = props.labelClose || "Close";


    if (showContent) {
        return <div>
            <div className="detail-modal-background" onClick={() => { setShowContent(!showContent) }}></div>
            <div className="detail-modal-elevated">
                <div className="detail-modal-content">
                    {props.children}
                </div>
                <div>
                    <button className="btn-default" onClick={() => { setShowContent(!showContent) }}>{labelClose}</button>
                </div>
            </div>
        </div>
    } else {
        return <div><button className="btn-default" onClick={() => { setShowContent(!showContent) }}>{labelOpen}</button></div>;
    }

}