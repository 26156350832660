import './style.css';
import logo from '../../static/icon.png';

function NavigationBar() {
    return (
        <div className="footer-basic bg-black footer-custom">
            <footer>
                <p className="copyright">Provided by <a href="https://www.pabst-software-design.com" target="_blank">Pabst Software
                & Design</a> &nbsp;© {new Date().getFullYear()} | <a target="_blank" href="https://pabst-software-design.com/index.php/legal-information/">Legal Information</a></p>
            </footer>
        </div>
    );
}

export default NavigationBar;
