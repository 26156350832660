
const ITEM_DELIMETER = ";";
function checklistToJSON(checklist, ignoreWarning = false) {
    checklist.templateLastSaved = Date.now();

    if (ignoreWarning) {
        return JSON.stringify(checklist);
    }

    // Properties
    if (checklist.title == "") throw new Error("Name darf nicht leer sein!");
    if (checklist.description == "") throw new Error("Beschreibung darf nicht leer sein!");
    if (checklist.author == "") throw new Error("Autor darf nicht leer sein!");
    if (checklist.version == "") throw new Error("Version darf nicht leer sein!");
    if (checklist.childEntries.length == 0) throw new Error("Checkliste muss mindestens einen Eintrag enthalten!");

    for (var entry of checklist.childEntries) {
        if (entry.title == "") {
            console.debug(entry);
            throw new Error("Mindestens ein Eintrag hat keinen Titel!");
        }
        if ((entry.type == "dropdown" || entry.type == "multiple_choice") && entry.options.length == 0)
            throw new Error("Mindestends ein Dropdown/Multiple-Choice-Eintrag hat keine Optionen!");
        if (entry.isMandatory == false) {

        }
    }
    return JSON.stringify(checklist);
}

function checklistFromJSON(content) {
    return JSON.parse(content);
}

function checklistFromCSV(content) {
    //var lines = content.split(/\r|\n/);
    var lines = content.split(/\r\n/);
    var ret = {};
    // Find delimeter
    const delimeter = lines[0][lines[0].length - 1]; // Last character of first line should be delimeter
    var entriesIndex = 0;
    var index = 0;
    for (var line of lines) {
        var lineContent = line.split(delimeter);
        if (lineContent[0] == "Name") {
            ret.title = lineContent[1];
        } else if (lineContent[0] == "Description") {
            ret.description = lineContent[1];
        } else if (lineContent[0] == "Author") {
            ret.author = lineContent[1];
        } else if (lineContent[0] == "Version") {
            ret.version = lineContent[1];
        } else if (lineContent[0] == "Title") {
            // Now starting with entries
            entriesIndex = index;
            break;
        }
        index++;
    }
    var childEntries = [];
    for (let i = entriesIndex + 1; i < lines.length; i++) {
        if (lines[i].length > 0) {
            var lineContent = lines[i].split(delimeter);
            var options = [];
            for (var x = 3; x < lineContent.length; x++) {
                if (lineContent[x] != "") options.push(lineContent[x]);
            }
            var item = {
                title: lineContent[0],
                description: lineContent[1],
                type: lineContent[2],
                options: options,
                attributes: [
                    {
                        key: "mandatory",
                        value: false,
                    },
                    {
                        key: "notes",
                        value: true,
                    },
                    {
                        key: "images",
                        value: true,
                    },
                ],
            };
            childEntries.push(item);
        }
    }
    ret.childEntries = childEntries;
    ret.templateLastSaved = 0;
    return ret;
}

function _seperateWithID(str) {
    if (str.constructor === Array) {
        return str.join(ITEM_DELIMETER);
    }
    return str;
}

export { checklistToJSON, checklistFromJSON, checklistFromCSV };