import React from 'react';

import { authenticationService } from 'Services/authentication.service';
import { userService } from 'Services/user.service';
import { DataGrid, deDE } from '@material-ui/data-grid';

import moment from 'moment';
import EditObjectDialog from 'Components/EditObjectDialog/EditObjectDialog';
import SimpleDialog from 'Components/SimpleDialog/SimpleDialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Tooltip } from '@material-ui/core';

class UsersPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            users: null,
            createDialog: {
                open: false,
                error: ""
            },
            deleteDialog: {
                open: false,
                user: -1
            },
            newUser: null,
        };
    }

    componentDidMount() {
        this.updateUi();
    }

    updateUi() {
        userService.getAll().then((users) => this.setState({ users }));
    }

    createNewUserDialog() {
        this.state.newUser = {
            email: "",
            firstname: "",
            lastname: "",
            company: "",
            password: "",
            role: "none",
        }
        const editProperties = {
            buttonLabel: "Neu Erstellen",
            dialogTitle: "Neuen Benutzer erstellen..",
            dialogDescription: null,
            onSave: (obj) => {
                userService.create(obj).then(resp => {
                    if (resp.error) {
                        this.setState({ createDialog: { open: true, error: "Error: " + resp.error } });
                    }
                    this.updateUi();
                });
            },
            objectToEdit: this.state.newUser,
            mapping: {
                email: {
                    label: "E-Mail",
                },
                firstname: {
                    label: "Vorname",
                },
                lastname: {
                    label: "Nachname",
                },
                company: {
                    label: "Firma",
                },
                password: {
                    label: "Passwort",
                },
                role: {
                    label: "Rolle",
                    type: "select",
                    value: 'none',
                    onChange: (value, obj) => {
                        if (value == "admin") {
                            obj.permissionLevel = 10;
                        } else if (value == "user") {
                            obj.permissionLevel = 1;
                        } else if (value == "none") {
                            obj.permissionLevel = 0;
                        }
                        return obj;
                    },
                    values: [
                        {
                            value: "none",
                            label: "None"
                        },
                        {
                            value: "user",
                            label: "User"
                        },
                        {
                            value: "admin",
                            label: "Admin"
                        },
                    ]
                },
            }
        };
        return <EditObjectDialog properties={editProperties}></EditObjectDialog>;
    }

    handleUserDelete(id) {
        this.setState({ deleteDialog: { open: true, user: id } });
    }

    deleteUser(id) {
        this.setState({ deleteDialog: { open: false } });
        userService.deleteEntity(id)
            .catch((error) => {
                console.error(error);
            }).finally(() => {
                this.updateUi();
            });
    }

    renderCellWithTooltip(params) {
        return <Tooltip title={params.value}><span>{params.value}</span></Tooltip>
    }

    render() {
        const { currentUser, users } = this.state;

        const columns = [
            // { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'user', hide: true },
            { field: 'id', headerName: 'Id', flex: 1 },
            { field: 'email', headerName: 'E-Mail', flex: 1, renderCell: this.renderCellWithTooltip },
            { field: 'firstname', headerName: 'Vorname', flex: 1 },
            { field: 'lastname', headerName: 'Nachname', flex: 1 },
            { field: 'company', headerName: 'Firma', flex: 1, renderCell: this.renderCellWithTooltip },
            { field: 'role', headerName: 'Rolle', flex: 1 },
            { field: 'permissionLevel', headerName: 'Permission-Level', flex: 1 },
            { field: 'createdAt', headerName: 'Erstellt am', flex: 1 },
            // { field: 'updatedAt', headerName: 'Geändert am', flex: 1 },
            { field: 'lastLoggedInAt', headerName: 'Zuletzt eingelogt am', flex: 1 },
            {
                field: 'actions', headerName: 'Aktionen', flex: 1,
                disableClickEventBubbling: true,
                renderCell: (params) => {
                    const editProperties = {
                        dialogTitle: "Benutzer bearbeiten..",
                        dialogDescription: null,
                        onSave: (obj) => {
                            userService.update(obj).then(resp => {
                                if (resp.error) {
                                    this.setState({ createDialog: { open: true, error: "Error: " + resp.error } });
                                }
                                this.updateUi();
                            });
                        },
                        objectToEdit: Object.assign(params.row.user, { "password": "" }),
                        mapping: {
                            firstname: {
                                label: "Vorname",
                            },
                            lastname: {
                                label: "Nachname",
                            },
                            company: {
                                label: "Firma",
                            },
                            password: {
                                label: "Passwort",
                            },
                            role: {
                                label: "Rolle",
                                type: "select",
                                value: params.row.role,
                                onChange: (value, obj) => {
                                    if (value == "admin") {
                                        obj.permissionLevel = 10;
                                    } else if (value == "user") {
                                        obj.permissionLevel = 1;
                                    } else if (value == "none") {
                                        obj.permissionLevel = 0;
                                    }
                                    return obj;
                                },
                                values: [
                                    {
                                        value: "none",
                                        label: "None"
                                    },
                                    {
                                        value: "user",
                                        label: "User"
                                    },
                                    {
                                        value: "admin",
                                        label: "Admin"
                                    },
                                ]
                            },
                        }
                    };
                    return <div style={{ display: "contents" }}> <IconButton
                        variant="outlined"
                        color="secondary"
                        disabled={params.row.id == this.state.currentUser.id}
                        onClick={() => this.handleUserDelete(params.row.id)}
                        style={{ padding: 4 }}>
                        <DeleteIcon />
                    </IconButton>
                        <EditObjectDialog
                            properties={editProperties}
                            style={{ padding: 4 }}></EditObjectDialog></div>;
                }
            },
        ];

        const rows = [];
        if (users != null)
            for (var user of users) {
                rows.push({
                    user: user,
                    id: user.id,
                    email: user.email,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    company: user.company,
                    role: user.role,
                    permissionLevel: user.permissionLevel,
                    createdAt: moment(user.createdAt).format("DD.MM.yyyy"),
                    updatedAt: moment(user.updatedAt).format("DD.MM.yyyy"),
                    lastLoggedInAt: moment(user.lastLoggedInAt).format("DD.MM.yyyy"),
                });
            }

        return (
            <div>
                <h1>Hi Admin!</h1>
                <hr />
                <h2>Alle Nutzer:</h2>
                <br />
                <div style={{ height: 400, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid hideFooterSelectedRowCount={true} isRowSelectable={false} loading={users == null} localeText={deDE.props.MuiDataGrid.localeText} columns={columns} rows={rows} />
                        </div>
                    </div>
                </div>
                <SimpleDialog title="Error"
                    open={this.state.createDialog.open}
                    onClose={() => this.setState({ createDialog: { open: false } })}
                    body={this.state.createDialog.error}></SimpleDialog>
                <SimpleDialog title="Benutzer löschen?"
                    open={this.state.deleteDialog.open}
                    closeLabel="Abbrechen"
                    onClose={() => this.setState({ deleteDialog: { open: false } })}
                    additionalActions={[<Button onClick={() => this.deleteUser(this.state.deleteDialog.user)} color="primary" autoFocus>
                        Ja
                    </Button>]}
                    body={"Möchtest du diesen Benutzer endgültig löschen? Das kann nicht rückgängig gemacht werden!"}></SimpleDialog>
                <div style={{ padding: 16 }}>
                    {this.createNewUserDialog()}
                </div>
            </div >
        );
    }
}

export { UsersPage };