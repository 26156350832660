export const util = {
    getPlatformName
};

function getPlatformName(s) {
    switch (s) {
        case "ios":
            return "iOS";
        case "android":
            return "Android";
        case "web":
            return "Web";
        default:
            return s;
    }
}